import { useZxing } from "react-zxing";

export const QrReader = ({ onResult, className }) => {
  const { ref } = useZxing({
    onDecodeResult(result) {
      onResult(result.getText());
    }
  });

  return <video className={className} ref={ref} />;
};
