import {format, parseISO} from 'date-fns';
import userNoImage from "../../assets/img/employee_no_image.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import api from "../../services/api";
import {STORE_CLOSING, STORE_OPENING} from "../../config/routesApi";
import Swal from "sweetalert2";
import {getResponseErrorMessage} from "../../utils/functions";
import {useEffect, useState} from "react";
import Load from "../../components/Load";

export default function CardEmployee ({ employee }) {
  const [load, setLoad] = useState(false);
  const image = employee.image_url ?? userNoImage;
  const [openStore, setOpenStore] = useState(false);

  useEffect(() => {
    setOpenStore(!!employee?.store?.store_opening_records_latest)
  }, [employee]);

  const registerStoreOpening = async (event) => {
    event.preventDefault();
    const {storeId, employeeId} = event.currentTarget.dataset;
    setLoad(true);

    await api.request({
      ...STORE_OPENING,
      data: {
        store_id: storeId,
        employee_id: employeeId
      }
    })
      .then(_ => {
        setOpenStore(true);
        dispatchEventUpdateStatusStore(employeeId);
      })
      .catch(error => {
        Swal.fire('Oops!', `Não foi possível fazer a leitura!${getResponseErrorMessage(error)}`, 'error');
        setOpenStore(false);
      })
      .then(() => { setLoad(false) });
  }
  const registerStoreClosing = async (event) => {
    event.preventDefault();
    const {storeId, employeeId} = event.currentTarget.dataset;
    setLoad(true);

    await api.request({
      ...STORE_CLOSING,
      data: {
        store_id: storeId,
        employee_id: employeeId
      }
    })
      .then(_ => {
        setOpenStore(false);
        dispatchEventUpdateStatusStore(employeeId);
      })
      .catch(error => {
        Swal.fire('Oops!', `Não foi possível fazer a leitura!${getResponseErrorMessage(error)}`, 'error');
        setOpenStore(true);
      })
      .then(() => { setLoad(false) });
  }

  const dispatchEventUpdateStatusStore = (employeeId) => {
    const event = new Event('updatedStatusStore', {bubbles: true, cancelable: true});
    document.getElementById(employeeId).dispatchEvent(event);
  }

  return (
    <div className="d-flex justify-content-center">
      {employee &&
      <div className="card border-0" style={{maxWidth: '640px'}}>
        <div className="row g-0">
          <div className="col-md-5 d-flex align-items-center p-3">
            <img src={image} className="img-fluid rounded-1" alt="imagem de perfil" />
          </div>
          <div className="col-md-7">
            <div className="card-body">
              <h5 className="card-title">{employee.name}</h5>
              <p className="card-text mb-1"><b>Loja</b>: <small className={`${openStore ? 'text-success' : 'text-danger'}`}><FontAwesomeIcon icon={faCircle} /></small> {employee.store.fantasy_name}</p>
              <p className="card-text mb-1">
                {(!openStore &&
                  <button onClick={registerStoreOpening} id={employee.id} data-store-id={employee.store.id} data-employee-id={employee.id} type="button" className="btn btn-sm btn-warning">Registrar abertura de loja</button>)
                  || (openStore && <button onClick={registerStoreClosing} id={employee.id} data-store-id={employee.store.id} data-employee-id={employee.id} type="button" className="btn btn-sm btn-danger">Registrar fechamento de loja</button>)
                }
              </p>
              <p className="card-text mb-1"><b>Função</b>: {employee.occupation}</p>
              <p className="card-text mb-1"><b>RG</b>: {employee.rg}</p>
              <p className="card-text mb-1"><b>CPF</b>: {employee.cpf}</p>
              <p className="card-text mb-1"><b>Possui chave</b>: {employee.is_access_key ? 'Sim' : 'Não'} | <b>Pode delegar</b>: {employee.is_delegate ? 'Sim' : 'Não'}</p>
              <span className={`badge rounded-pill mb-1 ${employee.is_access_blocked ? 'bg-danger' : 'bg-success'}`}>{employee.is_access_blocked ? 'Acesso bloqueado' : 'Acesso permitido'}</span>
              {!employee.is_access_blocked &&
              <p className="card-text mb-1"><b>Período de acesso</b>: {employee.start_access_time} às {employee.end_access_time}</p>}
              <p className="card-text"><small className="text-muted">Última atualização {format(parseISO(employee.updated_at), 'dd/MM/yyyy HH:mm')}</small></p>
            </div>
          </div>
        </div>
      </div>}
      {load && <Load />}
    </div>
  );
}