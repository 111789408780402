import "./style.scss";
import {useCallback} from "react";
export function Pagination ({ pagination, onPageChanged }) {
  const protocol = window.location.protocol;
  const urlNext = new URL(pagination.next_page_url || `${protocol}//${window.location.host}`);
  const urlPrev = new URL(pagination.prev_page_url || `${protocol}//${window.location.host}`);
  const nextPage = new URLSearchParams(urlNext.search);
  const prevPage = new URLSearchParams(urlPrev.search);

  const pages = useCallback(() => {
    let pages = [];
    let limitPages = 1;

    for (let i = limitPages; i > 0; i--) {
      let page = pagination.current_page - i;
      if (i === limitPages && (pagination.current_page - limitPages - 1) > 0) {
        pages.push(1);
        if (pagination.current_page > (limitPages + 2)) {
          pages.push('...');
        }
      }

      if (page > 0) {
        pages.push(page);
      }
    }

    pages.push(pagination.current_page || 1);

    for (let i = 1; i <= limitPages; i++) {
      let page = pagination.current_page + i;
      if (page <= pagination.last_page) {
        pages.push(page);
      }
      if (i === limitPages && pagination.current_page + limitPages < pagination.last_page) {
        if ((pagination.current_page + limitPages + 1) < pagination.last_page) {
          pages.push('...');
        }
        pages.push(pagination.last_page);
      }
    }

    return pages;
  }, [pagination]);

  return (
    <nav aria-label="Page navigation example">
      <p>Mostrando de { pagination.from || '0' } até { pagination.to || '0' } de { pagination.total || '0' } registros</p>
      <ul className="pagination justify-content-center">
        <li className={`page-item ${ pagination.current_page === 1 && 'disabled' }`}>
          <button className="page-link link-dark"
                  onClick={() => {
                    if (prevPage.has('page')) {
                      onPageChanged(prevPage.get('page'));
                    }
                  }}
          >Anterior</button>
        </li>
        {pages().map((page) => {
          return (
            <li key={`page-${page}${page === '...' ? Math.random() * Math.random() : ''}`} className="page-item">
              <button className={`page-link link-dark ${pagination.current_page === page && 'active'}`}
                      onClick={({ target }) => {
                        if (page === '...') return false;
                        target.blur();
                        onPageChanged(page);
                      }}
              >{page}</button>
            </li>
          )
        })}
        <li className={`page-item ${ pagination.current_page === pagination.last_page && 'disabled' }`}>
          <button className="page-link link-dark"
                  onClick={() => {
                    if (nextPage.has('page')) {
                      onPageChanged(nextPage.get('page'));
                    }
                  }}
          >Próximo</button>
        </li>
      </ul>
    </nav>
  );
}