import {HeaderLogo} from "../../components/HeaderLogo";
import {Tab} from "../../components/Tab";
import Load from "../../components/Load";
import Filter from "../../components/Filter";
import React, {useCallback, useEffect, useState} from "react";
import api from "../../services/api";
import {SERVICE_AUTHORIZATION_LIST} from "../../config/routesApi";
import Swal from "sweetalert2";
import {getResponseErrorMessage} from "../../utils/functions";
import {Pagination} from "../../components/Pagination";
import {format, parseISO, differenceInDays} from "date-fns";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {serviceAuthorization as routeServiceAuthorization} from "../../config/routes";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {SERVICE_AUTHORIZATION_FILTER_KEYWORD, SERVICE_AUTHORIZATION_FILTER_CRITERIA} from "../../utils/keysLocalStorage";

export default function ServiceAuthorization () {
  const [serviceAuthorizations, setEmployees] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [keyword, setKeyword] = useLocalStorage(SERVICE_AUTHORIZATION_FILTER_KEYWORD,'');
  const [criteria, setCriteria] = useLocalStorage(SERVICE_AUTHORIZATION_FILTER_CRITERIA, {page: 1, per_page: 5});
  const [load, setLoad] = useState(false);

  function handleSearch({ keyword }) {
    setKeyword(keyword);
    onPageChanged(1);
  }

  function onPageChanged (page) {
    setCriteria({...criteria, page});
  }

  const searchServiceAuthorizations = useCallback(async () => {
    setLoad(true);
    await api.request({...SERVICE_AUTHORIZATION_LIST, params: {keyword, ...criteria}})
      .then(response => {
        let responsePagination = response.data.data;
        let { data } = responsePagination;
        delete responsePagination.data;
        if (!Array.isArray(data)) {
          data = [];
        }
        setEmployees(data);
        setPagination(responsePagination);
      })
      .catch(error => {
        Swal.fire('Oops!', getResponseErrorMessage(error), 'error');
        setEmployees([]);
      })
      .then(() => setLoad(false));
  }, [keyword, criteria]);

  useEffect(() => {
    searchServiceAuthorizations();
  }, [criteria]);

  return (
    <main>
      <HeaderLogo />
      <Tab active="service-authorization" />
      <div className="container employee-list pt-3">
        <Filter onSearch={handleSearch} label="número do protocolo, suc, empresa, responsável e loja (nome fantasia)"/>
        <div className="table-responsive">
          <table className="table table-borderless">
            <thead>
            <tr>
              <th>#</th>
              <th>Protocolo</th>
              <th>Loja</th>
              <th>Empresa</th>
              <th>Data início e fim</th>
              <th>Hora início e fim</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            {serviceAuthorizations.map(serviceAuthorization => {
              const isEndedPeriod = differenceInDays(new Date(parseISO(serviceAuthorization.date_end)), new Date()) < 0;
              const styleEndedPeriod = isEndedPeriod ? 'bg-danger text-white' : '';
              return (
                  <tr key={serviceAuthorization.id}>
                    <td className={styleEndedPeriod}><Link
                        to={`${routeServiceAuthorization}/${serviceAuthorization.id}`} type="button" title="Ver"
                        className="btn btn-sm btn-light"><FontAwesomeIcon color="#bf2c37" icon={faEye}/></Link></td>
                    <td className={styleEndedPeriod}>{serviceAuthorization.protocol_number}</td>
                    <td className={styleEndedPeriod}>{serviceAuthorization.store.fantasy_name}</td>
                    <td className={styleEndedPeriod}>{serviceAuthorization.company}<br/><b>{serviceAuthorization.company_contact}</b>
                    </td>
                    <td className={styleEndedPeriod}>{format(parseISO(serviceAuthorization.date_start), 'dd/MM/yyyy')} até {format(parseISO(serviceAuthorization.date_end), 'dd/MM/yyyy')}</td>
                    <td className={styleEndedPeriod}>{serviceAuthorization.time_start}hrs
                      às {serviceAuthorization.time_end}hrs
                    </td>
                    <td className={styleEndedPeriod}><span
                        className={`status ${!serviceAuthorization.canceled ? 'active' : 'inactive'}`}/>{!serviceAuthorization.canceled ? 'Ativo' : 'Cancelado'}
                    </td>
                  </tr>
              )
            })}
            </tbody>
          </table>
        </div>
        <Pagination pagination={pagination} onPageChanged={onPageChanged}/>
      </div>
      {load && <Load/>}
    </main>
  );
}
