import React, {useState} from "react";
import {HeaderLogo} from "../../../components/HeaderLogo";
import {Tab} from "../../../components/Tab";
import Swal from "sweetalert2";
import CardEmployee from "../../../components/CardEmployee";
import { QrReader } from '../../../components/QrReader'
import "./style.scss";
import api from "../../../services/api";
import {EMPLOYEE_LIST} from "../../../config/routesApi";
import {getResponseErrorMessage} from "../../../utils/functions";
import Load from "../../../components/Load";
import Beep from "../../../components/Beep";
import {format} from "date-fns";
import accessLog from "../../../services/accessLog";

export default function QrCodeScanner () {
  const [toggleCamera, setToggleCamera] = useState(false);
  const [load, setLoad] = useState(false);
  const [employee, setEmployee] = useState(false);

  const handleClean = () => {
    setEmployee(false);
  }

  const handleToogleCamera = () => {
    if (toggleCamera) {
      setToggleCamera(false);
    } else {
      setToggleCamera(true);
    }
  }

  const handleScan = data => {
    if (data) {
      if (!/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(data)) {
        Swal.fire('Oops!', 'Não foi possível fazer a leitura, não é um QrCode Válido!', 'error');
        setToggleCamera(false);
        return false;
      }

      setToggleCamera(false);
      searchEmployee(data);
    }
  }

  const beepAlert = (isAlert) => {
    if (isAlert) {
      document.getElementById('notificacao').play();
    }
  }

  const searchEmployee = async (code) => {
    setLoad(true);
    await api.request({
      url: `${EMPLOYEE_LIST.url}/${code}`,
      method: EMPLOYEE_LIST.method
    })
      .then(response => {
        const { data } = response.data;
        setEmployee(data);
        const now = new Date();
        const startAccessTime = new Date(`${format(now, 'yyyy-MM-dd')} ${data.start_access_time}`);
        const endAccessTime = new Date(`${format(now, 'yyyy-MM-dd')} ${data.end_access_time}`);
        if (now.getTime() < startAccessTime.getTime() ||
          now.getTime() > endAccessTime.getTime() ||
          data.is_access_blocked
        ) {
          beepAlert(true);
        }
      })
      .catch(error => {
        Swal.fire('Oops!', `Não foi possível fazer a leitura!${getResponseErrorMessage(error)}`, 'error');
        setEmployee(false);
      })
      .then(() => {
        setLoad(false);
        accessLog({
          id: code,
          trigger: 'automatic'
        });
      });
  };

  return (
    <main>
      <HeaderLogo />
      <Tab active="qrcode" />
      <div className="container pt-3">
        <div className="d-flex justify-content-center mb-3">
          <button type="button" onClick={handleClean} className="btn btn-outline-secondary me-2">Limpar busca</button>
          <button type="button" onClick={handleToogleCamera} className="btn btn-dark">{toggleCamera ? 'Desativar' : 'Ativar'} câmera</button>
        </div>
        <CardEmployee employee={employee} />
        {toggleCamera && <QrReader onResult={handleScan} className="scanner" />}
      </div>
      <Beep />
      {load && <Load />}
    </main>
  );
}
