import axios from 'axios';
import {getToken, setToken} from './auth';
import {LOGIN, REFRESH_TOKEN} from '../config/routesApi';

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
})

api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${getToken()}`
    return config;
  }, error => Promise.reject(error)
)

api.interceptors.response.use((response) => (response), (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && originalRequest.url === LOGIN.url) {
    window.location = '/login';
    return Promise.reject(error);
  }

  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    return api.request(REFRESH_TOKEN)
      .then(response => {
        if (response.status === 200) {
          setToken(response.data.token);
          return api(originalRequest);
        }
      })
  }
  return Promise.reject(error);
});

export default api