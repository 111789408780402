import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import {home, employeeList, login, serviceAuthorization} from "./config/routes";
import { isAuthenticated } from "./services/auth";
import SignIn from "./pages/SignIn";
import ListEmployee from "./pages/Employee/List";
import QrCodeScanner from "./pages/Employee/QrCode";
import ServiceAuthorization from "./pages/ServiceAuthorization";
import ServiceAuthorizationShow from "./pages/ServiceAuthorization/show";
const PrivateRoute = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to={login} replace={true}/>
  }
  return <>{children}</>;
};

const RoutesApp = () => (
  <BrowserRouter>
    <Routes>
      <Route path={home} element={<PrivateRoute><QrCodeScanner /></PrivateRoute>} />
      <Route path={employeeList} element={<PrivateRoute><ListEmployee /></PrivateRoute>} />
      <Route path={serviceAuthorization} element={<PrivateRoute><ServiceAuthorization /></PrivateRoute>} />
      <Route path={`${serviceAuthorization}/:id`} element={<PrivateRoute><ServiceAuthorizationShow /></PrivateRoute>} />

      <Route path={login} element={<SignIn />} />
      <Route path="*" element={<h1>Page not found</h1>} />
    </Routes>
  </BrowserRouter>
);

export default RoutesApp;