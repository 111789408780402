import "./style.scss";
import {HeaderLogo} from '../../../components/HeaderLogo';
import {Tab} from "../../../components/Tab";
import {Pagination} from "../../../components/Pagination";
import Filter from "../../../components/Filter";
import {useCallback, useEffect, useState} from "react";
import api from "../../../services/api";
import {EMPLOYEE_LIST} from "../../../config/routesApi";
import {getResponseErrorMessage} from "../../../utils/functions";
import Swal from "sweetalert2";
import Load from "../../../components/Load";
import accessLog from "../../../services/accessLog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle, faEye} from "@fortawesome/free-solid-svg-icons";
import {ModalEmployee} from "../../../components/Modal/ModalEmployee";
import { Modal } from 'bootstrap'

export default function List () {
  const [employees, setEmployees] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [criteria, setCriteria] = useState({page: 1, per_page: 5});
  const [load, setLoad] = useState(false);
  let updated = false;

  function handleSearch({ keyword }) {
    setKeyword(keyword);
    onPageChanged(1);
  }

  async function handleAccessLog(event) {
    try {
      await accessLog({
        id: event.target.value,
        trigger: event.currentTarget.dataset.trigger
      })
      Swal.fire(
        'Log gerado com sucesso!',
        'O log de acesso foi gerado com sucesso!',
        'success'
      );
    } catch (error) {
      Swal.fire('Oops!', getResponseErrorMessage(error), 'error');
    }
  }

  function onPageChanged (page) {
    setCriteria({...criteria, page});
  }

  function handleShowEmployee (event) {
    const employeeId = event.currentTarget.value;
    const modalId = `modal-employee-${employeeId}`;
    (new Modal(`#${modalId}`)).show();
    attachEventAfterLoadModal({modalId, employeeId});
  }

  function attachEventAfterLoadModal ({modalId, employeeId}) {
    document.getElementById(employeeId).addEventListener('updatedStatusStore' , () => {
      updated = true;
    });

    document.getElementById(modalId).addEventListener('hidden.bs.modal', () => {
      if (updated) {
        searchEmployee();
        updated = false;
      }
    });
  }

  const searchEmployee = useCallback(async () => {
    setLoad(true);
    await api.request({...EMPLOYEE_LIST, params: {keyword, ...criteria}})
      .then(response => {
        let responsePagination = response.data.data;
        const { data } = responsePagination;
        delete responsePagination.data;
        setEmployees(data);
        setPagination(responsePagination);
      })
      .catch(error => {
        Swal.fire('Oops!', getResponseErrorMessage(error), 'error');
        setEmployees([]);
      })
      .then(() => setLoad(false));
  }, [keyword, criteria]);

  useEffect(() => {
    searchEmployee();
  }, [criteria]);

  return (
    <main>
      <HeaderLogo />
      <Tab active="list" />
      <div className="container employee-list pt-3">
        <Filter onSearch={handleSearch} label="nome, rg, cpf, ocupação e loja (nome fantasia)"/>
        <div className="table-responsive">
          <table className="table table-borderless">
            <thead>
            <tr>
              <th>#</th>
              <th>Nome</th>
              <th>Loja</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            {employees.map(employee => {
              return (
                  <tr key={employee.id}>
                    <td>
                      <button
                          value={employee.id}
                          onClick={handleShowEmployee}
                          type="button"
                          title="Ver dados do funcionário"
                          className="btn btn-sm btn-light">
                        <FontAwesomeIcon icon={faEye}/>
                      </button>
                      <button
                          data-trigger='manual'
                          value={employee.id}
                          onClick={handleAccessLog}
                          type="button"
                          title="Log"
                          className="btn btn-sm ms-3 btn-light">
                        Log
                    </button>
                  </td>
                  <td>{employee.name}</td>
                  <td><small className={`${!!employee?.store?.store_opening_records_latest ? 'text-success' : 'text-danger'}`}><FontAwesomeIcon icon={faCircle} /></small> {employee.store.fantasy_name}</td>
                  <td><span className={`status ${!employee.is_access_blocked ? 'active' : 'inactive'}`} />{ !employee.is_access_blocked ? 'Ativo' : 'Inativo'}</td>
                </tr>
              )
            })}
            </tbody>
          </table>
        </div>
        <Pagination pagination={pagination} onPageChanged={onPageChanged} />
      </div>
      {employees.map(employee => <ModalEmployee key={employee.id} employee={employee}/>)}
      {load && <Load />}
    </main>
  );
}
