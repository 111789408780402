import "./style.scss";
import logo from "./../../assets/img/valesul.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import api from "../../services/api";
import {LOGIN} from "../../config/routesApi";
import {home} from "../../config/routes";
import {isAuthenticated, setToken} from "../../services/auth";
import Load from "../../components/Load";
import { useNavigate } from 'react-router-dom';


function List() {
  let navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [load, setLoad] = useState(false);
  const classInput = {
    email: email && 'filled',
    password: password && 'filled'
  }

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(home);
    }
  });

  function handleChange (event) {
    const element = event.target;
    if (element.name === 'email') {
      setEmail(element.value);
    }
    if (element.name === 'password') {
      setPassword(element.value);
    }
    if (element.type === 'checkbox') {
      setRemember(true);
    }
  }

  function showHidePassword() {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  }

  function validateForm() {
    return !(!email || !password);
  }

  function handleLogin(event) {
    event.preventDefault();

    if (!validateForm()) return false;
    const data = {email, password, remember}

    setLoad(true);
    api.request({...LOGIN, data})
      .then(response => {
        setError(null)
        setToken(response.data.token);
        navigate(home)
      })
      .catch(error => {
        setError(error?.response?.data?.errors || 'Opss!!! Não foi possível efetuar o login');
      })
      .then(() => setLoad(false));
  }

  return (
    <main className="container">
      <div className="d-flex min-vh-100 align-items-center justify-content-center p-3 p-sm-0 mt-h-470">
        <div className="bg-white container-login position-relative">
          <h3><img src={logo} alt="Logo vale sul shopping" /></h3>
          <form method="POST" action="" onSubmit={handleLogin}>
            <div className="form-group pt-3 mb-3 position-relative">
              <input id="email" name="email" type="email"
                     className={`form-control ${classInput.email} ${error && 'is-invalid'}`}
                     autoComplete="email" required autoFocus
                     value={email}
                     onChange={handleChange}
              />
              <label htmlFor="email" className="label">Usuário</label>
              { error &&
              <span className="invalid-feedback" role="alert">
                                <strong>{error}</strong>
                            </span>}
            </div>
            <div className="form-group pt-3 mb-3 position-relative">
              <input id="password" name="password" type={showPassword ? 'text' : 'password'}
                     className={`form-control ${classInput.password}`}
                     value={password}
                     onChange={handleChange}
              />
              <label htmlFor="password" className="label">Senha</label>
              <div className="icon-password">
                <button type="button" className="btn btn-link px-0" data-toggle="tooltip" title={showPassword ? 'Ocultar senha' : 'Exibir senha'} onClick={showHidePassword}>
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              </div>
            </div>
            <div className="form-group mb-0 text-center">
              <div className="box-remember mb-3">
                <div className="d-flex justify-content-start align-items-center">
                  <div className="custom-control custom-checkbox text-left">
                    <input type="checkbox" className="custom-control-input" name="remember" id="remember" onChange={handleChange} />
                    <label className="custom-control-label m-0" htmlFor="remember">Lembrar-me</label>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn btn-access">Acessar</button>
            </div>
          </form>
        </div>
      </div>
      {load && <Load />}
    </main>
  )
}

export default List;