import {atob} from 'js-base64';
export const TOKEN_APP_NAME = '@ca_vss_token';

export const isAuthenticated = () => {
  const token = getToken();
  if (token) {
    let jwt = JSON.parse(atob(token.split('.')[1]))
    const exp = new Date(jwt.exp * 1000)
    if (exp >= new Date()) {
      return true;
    }
  }

  logout();

  return false;
}
export const getToken = () => (localStorage.getItem(TOKEN_APP_NAME) || '');
export const setToken = (token) => {
  localStorage.setItem(TOKEN_APP_NAME, String(token).replace('Bearer ', ''));
};
export const logout = () => {
  localStorage.removeItem(TOKEN_APP_NAME);
};