import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

export default function Filter ({onSearch, label}) {
  const [keyword, setKeyword] = useState('');

  function handleChangeInput(event) {
    setKeyword(event.target.value);
  }

  return (
      <>
          <div className="input-group">
              <input type="search" className="form-control dark" placeholder="Pesquisar..." aria-label="Pesquisar"
                     aria-describedby="button-addon2"
                     value={keyword}
                     onChange={handleChangeInput}
              />
              <button className="btn btn-outline-secondary"
                      type="button"
                      id="button-addon2"
                      onClick={() => {
                          onSearch({keyword})
                      }}
              >Pesquisar
              </button>
          </div>
          <small className="form-text text-muted mb-3">
              <FontAwesomeIcon color="#48abf7" icon={faInfoCircle}/> Campos disponíveis para o filtro: {label}.
          </small>
      </>
  )
}