import React, {useCallback, useEffect, useState} from "react";
import api from "../../services/api";
import {SERVICE_AUTHORIZATION_LIST} from "../../config/routesApi";
import Swal from "sweetalert2";
import {getResponseErrorMessage} from "../../utils/functions";
import {useParams} from "react-router-dom";
import Load from "../../components/Load";
import {HeaderLogo} from "../../components/HeaderLogo";
import {Tab} from "../../components/Tab";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faTools} from "@fortawesome/free-solid-svg-icons";
import {differenceInDays, format, parseISO} from "date-fns";
import {statusCarNameBadge, statusServiceNameBadge} from "../../utils/serviceAuthorizationStatus";
import {Link} from "react-router-dom";
import {serviceAuthorization as routeServiceAuthorization} from "../../config/routes";

export default function Show () {
  const { id } = useParams();
  const [load, setLoad] = useState(false);
  const [serviceAuthorization, setServiceAuthorization] = useState([]);
  const [departments, setDepartments] = useState([]);
  const searchServiceAuthorizations = useCallback(async () => {
    setLoad(true);
    await api.request({url: `${SERVICE_AUTHORIZATION_LIST.url}/${id}`, method: SERVICE_AUTHORIZATION_LIST.method})
      .then(response => {
        const { data } = response.data;
        setServiceAuthorization(data);
      })
      .catch(error => {
        Swal.fire('Oops!', getResponseErrorMessage(error), 'error');
        setServiceAuthorization([]);
      })
      .then(() => setLoad(false));
  }, [id]);

  useEffect(() => {
    searchServiceAuthorizations();
  }, []);

  useEffect(() => {
    let dataDepartments = [];
    if (Array.isArray(serviceAuthorization.items)) {
      serviceAuthorization.items.forEach(service => {
        if (!dataDepartments[service.department_id]) {
          dataDepartments[service.department_id] = { name: '', services: [] };
        }
        let complementName = service.pivot.is_roof_access ? ', com acesso ao telhado' : '';
        complementName += service.pivot.is_equipment_withdrawal ? ', com retirada de equipamento' : '';
        dataDepartments[service.department_id].name = service.department.name;
        dataDepartments[service.department_id].services.push({
          id: service.id,
          name: service.is_with_input ? `${service.name}: ${service.pivot.name}` : `${service.name}${complementName}`,
          status: service.pivot.status,
          comment: service.pivot.comment,
          updated_at: service.updated_at
        });
      })
      setDepartments(dataDepartments);
    }
  }, [serviceAuthorization]);

  const isEndedPeriod = serviceAuthorization.date_end ? differenceInDays(new Date(parseISO(serviceAuthorization.date_end)), new Date()) < 0 : true;
  return (
    <main className="pb-4">
      <HeaderLogo />
      <Tab active="service-authorization" />
      <div className="container employee-list pt-3">
        <div className="d-flex justify-content-end">
          <Link to={routeServiceAuthorization} className="btn btn-sm btn-dark"><FontAwesomeIcon icon={faChevronLeft} /> Voltar</Link>
        </div>

        {isEndedPeriod && <div className="p-3 my-4 bg-danger text-white rounded-1">**Atenção: o período de trabalho desta ordem de serviço terminou!</div>}

        <h3>Solicitante</h3>
        <div className="p-3 mb-4 bg-white rounded-1">
          <div className="container-fluid">
            <h5><b>Protocolo</b>: {serviceAuthorization.protocol_number}</h5>
            <div className="row">
            <div className="col-4"><b>Loja</b>: <br />{serviceAuthorization?.store?.fantasy_name}</div>
            <div className="col-4"><b>SUC</b>: <br />{serviceAuthorization.suc}</div>
            <div className="col-4"><b>Data de solicitação</b>: <br />{serviceAuthorization.created_at && format(parseISO(serviceAuthorization.created_at), 'dd/MM/yyyy HH:mm:ss')}</div>
            </div>
          </div>
        </div>

        <div className="row">
        {Object.entries(departments).map(([department_id, department]) => {
          return <div key={department_id} className="col-12 col-lg-6 mb-3">
            <div className="card rounded-1 border-0">
              <div className="card-body">
                <h5 className="card-title"><b>Departamento</b>: {department.name}</h5>
                <h6 className="card-subtitle mb-2 text-muted">Serviços</h6>
                {department.services.map(service => {
                  return <div key={service.id} className="list-group">
                    <a href="#" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                      <span className="text-white bg-secondary p-1 rounded-circle flex-shrink-0 text-center" style={{width: '32px', height: '32px'}}><FontAwesomeIcon icon={faTools} /></span>
                      <div className="d-flex gap-2 w-100 justify-content-between">
                        <div>
                          <h6 className="mb-0">{service.name} {statusServiceNameBadge(service.status)}</h6>
                          <p className="mb-0 opacity-75"><b>Comentário</b>: {service.comment}</p>
                        </div>
                        <small className="opacity-50 text-nowrap"><b>Última atualização</b>: <br />{format(parseISO(service.updated_at), 'dd/MM/yyyy HH:mm:ss')}</small>
                      </div>
                    </a>
                  </div>
                })}
              </div>
            </div>
          </div>
        })}
        </div>
        <hr />
        <h3>Prestador</h3>
        <div className="p-3 mb-4 rounded-1 bg-white">
          <div className="container-fluid">
            <h5><b>Empresa</b>: {serviceAuthorization.company}</h5>
            <div className="row">
              <div className="col-4"><b>Contato</b>: <br />{serviceAuthorization.company_contact}</div>
              <div className="col-4"><b>Data de ínício e término</b>:<br />
                {serviceAuthorization.date_start && format(parseISO(serviceAuthorization.date_start), 'dd/MM/yyyy')} até {serviceAuthorization.date_end && format(parseISO(serviceAuthorization.date_end), 'dd/MM/yyyy')}</div>
              <div className="col-4"><b>Período</b>: <br />{serviceAuthorization.time_start} às {serviceAuthorization.time_end}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="card rounded-1 border-0">
              <div className="card-body">
                <h5 className="card-title">Pessoas</h5>
                <ol className="list-group list-group-numbered">
                  {(serviceAuthorization.authorized_people || []).map(people => {
                    return <li key={people.id} className="list-group-item"><b>Nome</b>: {people.name} <br /><b className="ms-3">RG</b>: {people.rg}</li>
                  })}
                </ol>
            </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card rounded-1 border-0">
              <div className="card-body">
                <h5 className="card-title">Carros</h5>
                <ol className="list-group list-group-numbered">
                  {(serviceAuthorization.cars || []).map(car => {
                    return <li key={car.id} className="list-group-item">
                      <b>Marca</b>: {car.car_brand} <br />
                      <b className="ms-3">Modelo</b>: {car.car_model} <br />
                      <b className="ms-3">Placa</b>: <span className="text-uppercase">{car.car_plate}</span> <br />
                      <span className="ms-3">{statusCarNameBadge(car.status_overnight)}</span>
                    </li>
                  })}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      {load && <Load />}
    </main>
  );
}
