import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

export function TabItem ({ name, link, active, icon, handleClick }) {
  const classItem = 'nav-link link-dark tab-link';
  return (
    <li className="nav-item">
      <Link className={`${classItem} ${active && 'active'}`} to={link} onClick={handleClick}>
        <span className="d-block text-center"><FontAwesomeIcon icon={icon} /></span> {name}
      </Link>
    </li>
  )
}