export const getResponseErrorMessage = (error) => {
  let message = '';
  if (error.response?.status === 422) {
    const messages = error.response.data.message;
    Object.entries(messages).forEach(([key, item]) => {
      message += `<br />${Array.isArray(item) ? item.join('<br>') : item}`;
    });
  }
  if (error.response?.status === 403) {
    message += '<br /><span class="text-danger">Acesso negado</span>';
  }
  return message;
}