import CardEmployee from "../CardEmployee";

export function ModalEmployee ({ employee }) {
  return (
    <div className="modal fade" id={`modal-employee-${employee.id}`} tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5">Funcionário {employee.name}</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Fechar"></button>
          </div>
          <div className="modal-body bg-light">
            <CardEmployee employee={employee} />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-dark" data-bs-dismiss="modal">Fechar</button>
          </div>
        </div>
      </div>
    </div>
  )
}