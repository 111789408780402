const PENDING = 1;
const APPROVED = 2;
const DISAPPROVED = 3;
const SUSPENDED = 4;

const statusNameBadge = {
  [PENDING]: <span className="badge bg-warning">Pendente</span>,
  [APPROVED]: <span className="badge bg-success">Aprovado</span>,
  [DISAPPROVED]: <span className="badge bg-danger">Negado</span>,
  [SUSPENDED]: <span className="badge bg-dark">Suspenso</span>
}

export const statusServiceNameBadge = (status) => {
  return statusNameBadge[status] || '';
}


const statusCarBadge = {
  0: <span className="badge bg-danger">Pernoite não liberado</span>,
  1: <span className="badge bg-success">Pernoite liberado</span>,
}

export const statusCarNameBadge = (status) => {
  return statusCarBadge[status] || '';
}