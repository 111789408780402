/* Autenticação */
export const LOGIN = {
  url: 'login',
  method: 'POST'
};

export const REFRESH_TOKEN = {
  url: 'refresh',
  method: 'POST'
};

export const EMPLOYEE_LIST = {
  url: 'employee',
  method: 'GET'
};

export const EMPLOYEE_LOG = {
  url: 'employee/log',
  method: 'GET'
};

export const SERVICE_AUTHORIZATION_LIST = {
  url: 'service-authorization',
  method: 'GET'
};

export const STORE_OPENING = {
  url: 'store/opening',
  method: 'POST'
};

export const STORE_CLOSING = {
  url: 'store/closing',
  method: 'POST'
};
