import "./style.scss";
import {faList, faQrcode, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {faIdBadge} from "@fortawesome/free-regular-svg-icons";
import {employeeList, home, login, serviceAuthorization} from "../../config/routes";
import {TabItem} from "./TabItem";
import {setToken} from "../../services/auth";

export function Tab ({ active }) {
  return (
    <ul className="nav justify-content-center mb-5 tab-background">
      <TabItem name="Lista" icon={faList} active={active === 'list'} link={employeeList} />
      <TabItem name="Autorização" icon={faIdBadge} active={active === 'service-authorization'} link={serviceAuthorization} />
      <TabItem name="QrCode" icon={faQrcode} active={active === 'qrcode'} link={home} />
      <TabItem name="Sair" icon={faSignOutAlt} active={false} link={login} handleClick={() => {setToken('')}}/>
    </ul>
  );
}